
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "TaskName",
  props: {
    task: {},
    isPanel: {default: false}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  },
  methods: {
    onClick(id) {
      if (this.isPanel) {
        this.loadData(id, 'TASK')
      } else {

        store.dispatch(Actions.LOAD_TASK, id)
      }
    }
  }
})
